import isEmpty from 'lodash/isEmpty';
import {TYPES} from '../config';

const SessionDateUtil = {
    getCoachName(coachingSession, appointmentData) {
        if (!isEmpty(coachingSession)) {
            return coachingSession?.staff?.fullName;
        } else if (!isEmpty(appointmentData)) {
            return appointmentData?.coach;
        } else {
            return '';
        }
    },
    getScheduledDate(coachingSession, appointmentData, type, intlFormat) {
        const helper = unixTime => {
            if (type === 1) {
                const time = `${intlFormat(unixTime, {
                    month: 'long',
                    day: 'numeric',
                })} at ${intlFormat(unixTime, {
                    hour: 'numeric',
                    minute: 'numeric',
                })}`;

                return time;
            } else if (type === 2) {
                const time = intlFormat(unixTime, {
                    weekday: 'long',
                    month: 'long',
                    day: 'numeric',
                });

                return time;
            } else if (type === 3) {
                const time = `${intlFormat(unixTime, {
                    weekday: 'long',
                    month: 'long',
                    day: 'numeric',
                })} at ${intlFormat(unixTime, {
                    hour: 'numeric',
                    minute: 'numeric',
                })}`;

                return time;
            }
        };

        if (!isEmpty(coachingSession)) {
            const d = new Date(coachingSession?.clientStartDateTimeUTC); // The 0 there is the key, which sets the date to the epoch

            return helper(d, type);
        } else if (!isEmpty(appointmentData)) {
            const d = new Date(appointmentData?.startDate);

            return helper(d, type);
        } else {
            return '';
        }
    },
    getScheduledTime(coachingSession, appointmentData, intlFormat) {
        const helper = d => {
            const time = `${intlFormat(d, {
                hour: 'numeric',
                minute: 'numeric',
                timeZoneName: 'short',
            })}`;

            return time;
        };

        if (!isEmpty(coachingSession)) {
            const d = new Date(coachingSession?.clientStartDateTimeUTC); // The 0 there is the key, which sets the date to the epoch

            return helper(d);
        } else if (!isEmpty(appointmentData)) {
            const d = new Date(appointmentData?.startDate);

            return helper(d);
        } else {
            return '';
        }
    },
    getCallType(coachingSession) {
        const values = TYPES;
        const type = Object.keys(values).map(key => {
            if (values[key] === coachingSession?.sessionType) {
                return key;
            } else {
                return '';
            }
        });

        return type;
    },
    getCallMethod(appointmentData) {
        return appointmentData?.method;
    },
    getCallMethodDetails() {
        return 'From a 650 or 925 area code';
    },
    getCallTypeV2(text) {
        const ary = text?.split(':') ?? '';
        const poppedValue = ary?.length ? ary?.pop() : '';
        const formattedValue = poppedValue
            ?.toString()
            .replace('(Spanish)', '')
            .trim();

        return formattedValue?.replace(' ', '')?.toLocaleLowerCase();
    },
};

export default SessionDateUtil;
