import PropTypes from 'prop-types';
import HorizontalRule from '@teladoc/pulse/ui/HorizontalRule';
import {useL10nDateTimeContext} from '@teladoc/pulse/ui/Context/l10n/DateTime';
import useTransLoader from 'i18n/use-trans-loader';
import css from '../ConfirmationDetails.scss';
import SessionDateUtil from '../../utils/session-date-util';

const FullView = ({appointmentData, coachingSession}) => {
    const {t} = useTransLoader('confirmation');
    const {intlFormat} = useL10nDateTimeContext();
    let type = appointmentData.type;

    if (!type) {
        type = coachingSession?.reason?.reasonDesc;
    }

    const callType = SessionDateUtil.getCallTypeV2(type);

    return (
        <div>
            <HorizontalRule spaceTop={24} spaceBottom={24} />
            <div className={css.newDetail}>
                {t('sessionScheduledCoach')}
                <p className={css.newDetailData}>
                    {SessionDateUtil.getCoachName(
                        coachingSession,
                        appointmentData
                    )}
                </p>
            </div>
            <div className={css.newDetail}>
                {t('date')}
                <p className={css.newDetailData}>
                    {SessionDateUtil.getScheduledDate(
                        coachingSession,
                        appointmentData,
                        2,
                        intlFormat
                    )}
                </p>
            </div>
            <div className={css.newDetail}>
                {t('time')}
                <p className={css.newDetailData}>
                    {SessionDateUtil.getScheduledTime(
                        coachingSession,
                        appointmentData,
                        intlFormat
                    )}
                </p>
            </div>
            <div className={css.newDetail}>
                {t('sessionScheduledType')}
                <p className={css.newDetailData}>{t(callType)}</p>
            </div>
            <div className={css.newDetail}>
                {t('sessionScheduledMethod')}
                <div className={css.newDetailData}>
                    {t('phoneCall')}
                    <p className={css.newDetailDataSmall}>{t('callFrom')}</p>
                </div>
            </div>
        </div>
    );
};

FullView.propTypes = {
    appointmentData: PropTypes.object,
    coachingSession: PropTypes.object,
};

export default FullView;
